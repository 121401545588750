@import 'tailwindcss/base';
@import 'base/*';
@import 'components/*';
@import 'vendor/*';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'utilities/*';


.react-multiple-carousel__arrow {
  background-color: rgba(232, 242, 238, 0.9) !important;
  border: 1px solid rgba(11, 131, 129, 0.5) !important;
}

.react-multiple-carousel__arrow::before {
  color: rgba(0, 0, 0, 1) !important;
}

.react-multiple-carousel__arrow:hover {
  background-color: rgba(11, 131, 129, 0.9) !important;
  border: 1px solid rgba(11, 131, 129, 0.5) !important;
}

.react-multiple-carousel__arrow:hover::before {
  color: rgba(255, 255, 255, 1) !important;
}

.react-multi-carousel-dot > button {
  background-color: rgba(255, 255, 255, 1) !important;
  border: 1px solid rgba(11, 131, 129, 1) !important;
}

.react-multi-carousel-dot--active > button {
  background-color: rgba(11, 131, 129, 1) !important;
}

@media only screen and (max-width: 768px) {
  .react-multiple-carousel__arrow {
    display: none !important;
  }
}
