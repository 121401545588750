input[type='checkbox']:checked + label .checkbox {
  @apply bg-teal-4;
  @apply border-teal-4;

  & svg {
    @apply block;
    @apply stroke-white;
  }
}

input[type='checkbox']:focus + label .checkbox {
  @apply ring;
}

input[type='checkbox']:disabled + label .checkbox {
  @apply bg-grey-3;
  @apply border-grey-6;
  @apply cursor-not-allowed;

  & svg {
    @apply stroke-grey-6;
  }
}
