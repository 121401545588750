@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/SuisseIntl-Regular-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Regular-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Intl';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/SuisseIntl-RegularItalic-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-RegularItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/SuisseIntl-SemiBold-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-SemiBold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Intl';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/SuisseIntl-SemiBoldItalic-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-SemiBoldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/SuisseIntl-Bold-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Bold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Intl';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/SuisseIntl-BoldItalic-WebXL.woff2') format('woff2'),
    url('/fonts/SuisseIntl-BoldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Suisse Works';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/SuisseWorks-Regular-WebS.woff2') format('woff2'),
    url('/fonts/SuisseWorks-Regular-WebS.woff') format('woff');
}
