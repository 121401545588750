:root {
  --radix-toast-viewport-padding: 24px;

  @screen lg {
    --radix-toast-viewport-padding: 48px;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--radix-toast-viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--radix-toast-viewport-padding)));
  }
}

.toast[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toast[data-state='closed'] {
  animation: hide 100ms ease-in;
}

.toast[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}

.toast[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}

.toast[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}
