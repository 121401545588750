.loader {
  @apply block;
  @apply rect-24;
  @apply relative;
}

.loader div {
  animation: loader-spinner 0.8s linear infinite;
  transform-origin: 12px 12px;
}

.loader div:after {
  @apply absolute;
  @apply bg-current;
  @apply block;
  @apply h-6;
  @apply rounded-md;
  @apply top-px;

  content: '';
  height: 6px;
  left: 11px;
  width: 1.5px;
}

.loader div:nth-child(1) {
  animation-delay: -0.7s;
  transform: rotate(0deg);
}

.loader div:nth-child(2) {
  animation-delay: -0.6s;
  transform: rotate(45deg);
}

.loader div:nth-child(3) {
  animation-delay: -0.5s;
  transform: rotate(90deg);
}

.loader div:nth-child(4) {
  animation-delay: -0.4s;
  transform: rotate(135deg);
}

.loader div:nth-child(5) {
  animation-delay: -0.3s;
  transform: rotate(180deg);
}

.loader div:nth-child(6) {
  animation-delay: -0.2s;
  transform: rotate(225deg);
}

.loader div:nth-child(7) {
  animation-delay: -0.1s;
  transform: rotate(270deg);
}

.loader div:nth-child(8) {
  animation-delay: 0s;
  transform: rotate(315deg);
}

@keyframes loader-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
